import { template as template_15ea554dc5164ee4a0f7ca256a86bcbf } from "@ember/template-compiler";
const FKText = template_15ea554dc5164ee4a0f7ca256a86bcbf(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
