import { template as template_b15013e4ac1b4d4489210d630b2e9d2d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_b15013e4ac1b4d4489210d630b2e9d2d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
