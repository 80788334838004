import { template as template_5b11db10f25843bf87a3d6cea178a707 } from "@ember/template-compiler";
const FKLabel = template_5b11db10f25843bf87a3d6cea178a707(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
