import { template as template_4e0eb30a7d1e4bceb00f099b3d588df4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_4e0eb30a7d1e4bceb00f099b3d588df4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
