import { template as template_8ec6febb475e44e1bd8792a1a85df9e9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8ec6febb475e44e1bd8792a1a85df9e9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
